import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { Box } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "box"
    }}>{`Box`}</h1>
    <p>{`Box is a simple component that supports all system properties. You can do nearly everything with it!`}</p>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'<Box p={10} display=\"flex\" justifyContent=\"space-between\" bg=\"#bada55\">\n  <Box mb=\"sm\" p={20} bg=\"white\">\n    Left\n  </Box>\n  <Box p={20} bg=\"white\">\n    Middle\n  </Box>\n  <Box p={20} bg=\"white\">\n    Right\n  </Box>\n</Box>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Box,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Box p={10} display="flex" justifyContent="space-between" bg="#bada55" mdxType="Box">
    <Box mb="sm" p={20} bg="white" mdxType="Box">
      Left
    </Box>
    <Box p={20} bg="white" mdxType="Box">
      Middle
    </Box>
    <Box p={20} bg="white" mdxType="Box">
      Right
    </Box>
  </Box>
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <Props of={Box} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      